import React from 'react';
// import TechStack from './TechStack';
import {Container, Typography} from '@mui/material';

const About = () => {
  return (
    <Container className="aboutSection" id="aboutSection">

      <Typography variant='h2'>Hello, I am Gordon Hui.</Typography>
      <Typography variant='h5'> I am a full-stack software engineer. My day-to-day focuses on React/Redux on the frontend and Node/Express server design on the backend. I am trying to improve quality of life one application at a time. I am looking to work on products that are innovative, exceptional, and accessible that are impactful in our daily lives. Here is a list of the work I have done - 
      </Typography>
      {/* <Typography variant='h5'>something eye opening here</Typography> */}

      {/* <TechStack/> */}
  
    </Container>
  )
}

export default About;