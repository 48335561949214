import * as React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Body from '../components/Body';
import "../styles/global.css"

// markup
const IndexPage = () => {
  return (
    <div>
      <Header />
      <Body />
      <Footer />
    </div>
  );
};

export default IndexPage;
